import * as React from "react";
import { Builder } from "@builder.io/react";
import PricingV2FAQsContainer from "../../../containers/PricingV2FAQsContainer/PricingV2FAQsContainer";
import { useBuilderFaqs } from "../../../hooks/useBuilderFaqs";

type FaqsContainerBcPropsType = {
  faqsContent: { faq: { id: string } }[];
  faqsTitle: string;
  faqsSubcopy: string;
};

const FaqsContainerBc: React.FC<FaqsContainerBcPropsType> = ({
  faqsContent,
  faqsSubcopy,
  faqsTitle,
}) => (
  <PricingV2FAQsContainer
    contentFaqsP={{
      contentFaqsList: useBuilderFaqs(faqsContent),
      contentFaqsSubcopy: faqsSubcopy,
      contentFaqsTitle: faqsTitle,
    }}
    className={""}
  />
);

export default FaqsContainerBc;

export const RegisterFaqsContainer = () => {
  Builder.registerComponent(FaqsContainerBc, {
    name: "faqs-accordion",
    inputs: [
      {
        name: "faqsContent",
        type: "list",
        subFields: [
          {
            name: "faq",
            type: "reference",
            model: "faq",
          },
        ],
      },
      {
        name: "faqsTitle",
        type: "string",
      },
      {
        name: "faqsSubcopy",
        type: "richText",
      },
    ],
  });
};
