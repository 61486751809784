type SPURLSearchParamsGetType = (
  queryParam: string,
  target: string
) => string | boolean;

export default class SPURLSearchParams {
  static get: SPURLSearchParamsGetType = (queryParam, target) => {
    const searchParams = new URLSearchParams(queryParam);

    return searchParams.get(target);
  };
}
