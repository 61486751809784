import { useState, useEffect, useCallback } from "react";
import { FaqsC } from "@types";
import SPfetch from "../services/SPFetch.class";

type BuilderFaqsContentTypes = { faq: { id: string } }[];
type FaqsMapType = {
  results: {
    data: {
      answer: string;
      question: string;
    };
  }[];
};

export const useBuilderFaqs = (faqsContent: BuilderFaqsContentTypes) => {
  const [faqs, setFaqs] = useState<FaqsC[]>([]);

  const generateFaqsMap = useCallback(() => {
    return faqsContent
      ? faqsContent
          .filter(content => content.hasOwnProperty("faq"))
          .map(content => {
            const url = `https://cdn.builder.io/api/v3/content/faq?apiKey=${process.env.GATSBY_BUILDER_API_KEY}&query.id=${content.faq.id}&limit=1`;
            return SPfetch.get(url);
          })
      : null;
  }, [faqsContent]);

  const handlePromiseResponse = async (faqsMap: Promise<FaqsMapType>[]) => {
    const faqsResponse = await Promise.all(faqsMap);
    return faqsResponse
      .map<Queries.builder_FaqData>(result => result.results[0].data)
      .map<FaqsC>(faq => ({
        faqsC: {
          questionsAnswer: faq.answer!,
        },
        title: faq.question!,
      }));
  };

  useEffect(() => {
    let isMounted = true;
    const faqsMap = generateFaqsMap();
    (async () => {
      if (faqsMap && faqsMap.length > 0) {
        const faqsList = await handlePromiseResponse(faqsMap);
        if (!isMounted) return;
        setFaqs([...faqsList]);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [generateFaqsMap]);

  return faqs;
};
