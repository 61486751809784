import React, { useState } from "react";
import { useDelayUnmount } from "../../../hooks/useDelayUnmount";
import styles from "./PricingFAQAccordion.module.scss";

interface PricingFAQAccordionProps {
  title: string;
  content: string;
  id: string;
}
const PricingFAQAccordion: React.FC<PricingFAQAccordionProps> = ({
  title,
  content,
  id,
}) => {
  const [active, setActive] = useState<boolean>(false);

  const showDiv = useDelayUnmount(active, 240);
  const activeStyle = { animation: "fadeInSimple 450ms ease-in" };
  const inactiveStyle = {
    animation: "fadeOutSimple 470ms ease-out",
    animationFillMode: "forwards",
  };

  const handleClick = (isActive: boolean) => {
    setActive(isActive);
  };

  const faqAccordionClasses = `${styles.faqAccordion} ${
    active ? styles.active : ""
  }`;

  return (
    <div
      data-testid="div:PricingFAQAccordion"
      className={styles.faqAccordionContainer}
    >
      <div
        data-testid="div:firstContainer:PricingFAQAccordion"
        className={faqAccordionClasses}
        onClick={() => handleClick(!active)}
      >
        <p className={styles.faqAccordionTitle}>{title}</p>
        <svg
          className="icon"
          xmlns="http://www.w3.org/2000/svg"
          fill="#333"
          width="24"
          height="24"
        >
          <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path>
          <path d="M15.88 9.29L12 13.17 8.12 9.29a.996.996 0 10-1.41 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59a.996.996 0 000-1.41c-.39-.38-1.03-.39-1.42 0z"></path>
        </svg>
      </div>

      {showDiv && (
        <div
          id={id}
          data-testid={id}
          dangerouslySetInnerHTML={{ __html: content }}
          className={styles.faqPanel}
          style={active ? activeStyle : inactiveStyle}
        />
      )}
    </div>
  );
};

export default PricingFAQAccordion;
