import * as React from "react";
import SignUpForm from "../../SignUpForm/SignUpForm";
import { Builder } from "@builder.io/react";
import { RedirectDataType } from "@components/SignUpForm/helpers/submitForm";
import BuilderStringInput from "@utils/BuilderInputs/BuilderStringInput";
import BuilderBooleanInput from "@utils/BuilderInputs/BuilderBooleanInput";

type SignUpFormBCPropsType = {
  signUpTitle?: string;
  ctaCopy?: string;
  redirectData?: RedirectDataType;
};

const defaultValue = "Start My Free Trial Now";

const SignUpFormBC: React.FC<SignUpFormBCPropsType> = ({
  signUpTitle,
  ctaCopy,
  redirectData,
}) => (
  <SignUpForm
    signUpTitle={signUpTitle}
    ctaCopy={ctaCopy || defaultValue}
    page="SignUp"
    redirectData={redirectData}
  />
);

export default SignUpFormBC;

export const RegisterSignUpForm = () => {
  Builder.registerComponent(SignUpFormBC, {
    name: "signup-form",
    inputs: [
      {
        name: "signUpTitle",
        type: "string",
      },
      {
        name: "ctaCopy",
        type: "string",
        defaultValue,
      },
      {
        name: "redirectData",
        type: "object",
        subFields: [
          new BuilderBooleanInput("customRedirect", { defaultValue: false }),
          new BuilderStringInput("redirectURL", { defaultValue: "" }),
        ],
      },
    ],
  });
};
