import React from "react";
import { PricingFAQAccordion } from "../../components/Accordions";
import { ContentFaqsPType } from "@types";

import styles from "./PricingV2FAQsContainer.module.scss";

const PricingV2FAQsContainer: React.FC<{
  contentFaqsP: ContentFaqsPType;
  className: string;
}> = ({ contentFaqsP, className }) => {
  const { contentFaqsList, contentFaqsSubcopy, contentFaqsTitle } =
    contentFaqsP;

  const hasClassName = className ? className : "";

  return (
    <section className={`full-width ${hasClassName}`}>
      <div className={`full-width narrow ${styles.faqsHeader}`}>
        <h2>{contentFaqsTitle}</h2>
        <p
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: contentFaqsSubcopy }}
        />
      </div>

      <div className={styles.pricingFaq}>
        {contentFaqsList?.map((faq, index) => (
          <PricingFAQAccordion
            key={index}
            id={`faq-panel-${index}`}
            title={faq.title}
            content={faq.faqsC.questionsAnswer}
          />
        ))}
      </div>
    </section>
  );
};

export default PricingV2FAQsContainer;
